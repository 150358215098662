import React from "react";

const Sidebar = ({ items, language, children }) => {
    return (
        <div className="sidebar">
            <div className="sidebar__menu">
                {items.map((item, index) => (
                    <a key={index} href={item.path}>
                        <button
                            className={
                                "sidebar__item" +
                                (item.selected ? " font-bold" : "")
                            }
                        >
                            {item.text}
                        </button>
                    </a>
                ))}
                <div>
                    <a href={`/${language}/manual-full/#`}>
                        <button className="sidebar__item italic text-sm font-light">
                            View all on a single page
                        </button>
                    </a>
                </div>
            </div>
            <div className="sidebar__content">{children}</div>
        </div>
    );
};

export default Sidebar;

import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import Raw from "components/raw";
import Sidebar from "components/sidebar";
import { buildReplaceForStandalonePage } from "utils/html";

export const query = graphql`
    query($id: String!, $language: String!) {
        ...seo
        current: allMarkdownRemark(filter: { id: { eq: $id } }) {
            edges {
                node {
                    id
                    html
                    frontmatter {
                        description
                        title
                    }
                    translation {
                        frontmatter {
                            language
                        }
                        fields {
                            path
                        }
                    }
                    media {
                        relativePath
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid_noBase64
                                ...GatsbyImageSharpFluidLimitPresentationSize
                            }
                        }
                    }
                }
            }
        }
        lang(key: $language) {
            ...footer
            guide {
                pages
                menu {
                    menuItems {
                        title
                        url
                    }
                }
            }
            others: guidePages {
                id
                fields {
                    path
                    relative
                }
                frontmatter {
                    title
                }
            }
        }
    }
`;

export default function PageTemplate({
    data: {
        seo,
        current: {
            edges: [{ node }],
        },
        lang: { footer, guide, others },
    },
    pageContext: { language },
}) {
    const translations = (node.translation || []).map(t => ({
        key: t.frontmatter.language,
        link: "/" + t.fields.path,
    }));
    const menu = guide.menu;
    const pages = others.map(other => ({
        relative: other.fields.relative,
        path: "/" + other.fields.path + "/#",
        text: other.frontmatter.title,
        selected: other.id === node.id,
    }));
    pages.sort(
        (a, b) =>
            guide.pages.indexOf(a.relative) - guide.pages.indexOf(b.relative)
    );

    return (
        <Layout menu={menu} footer={footer} translations={translations}>
            <SEO
                title={node.frontmatter.title}
                description={node.frontmatter.description}
                language={language}
                seo={seo}
            />
            <Sidebar items={pages} language={language}>
                <section className="guide section">
                    <div className="container mx-auto">
                        <Raw
                            html={node.html}
                            replace={buildReplaceForStandalonePage(
                                "manual",
                                language,
                                node.media
                            )}
                        />
                    </div>
                </section>
            </Sidebar>
        </Layout>
    );
}
